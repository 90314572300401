<template>
	<div v-if="dataInfo.villageName" class="case-detail" v-title="dataInfo.villageName+'——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<!-- 头部导航栏 -->
		<NavBar></NavBar>
		<div class="box">
			<img class="bran" src="../image/case/header_pc.jpg" alt="" />
		</div>
        <div class="box-mob">
            <img
				class="bran"
				src="../image/case/header_mob.jpg"
				alt=""
			/>
		</div>
		<div class="content ">
			<div class="left f-y-s-c">
				<div class="info f-y-s-s">
					<span class="title eps2">{{ dataInfo.villageName }}</span>
					<div class="det-box f-x-s-c">
						<span class="text1">来源：千家美-{{formartData('store',dataInfo.salesRoomNo)}}</span>
						<span class="text2">发布时间：{{dataInfo.createTime && dataInfo.createTime.slice(0,10)}}</span>
					</div>
					<div class="swiper-box">
						<swiper
							class="swiper gallery-top"
							:options="swiperOptionTop"
							ref="swiperTop"
						>
							<swiper-slide 
                                v-for="(item,index) in dataInfo.carouselImgUrl"
                                :key="index"    
                                class="slide-item">
								<img
									class="img"
									:src="item"
									alt=""
								/>
							</swiper-slide>
							<!-- <swiper-slide class="slide-item">
								<img
									class="img"
									src="../image/common/test2.jpg"
									alt=""
								/>
							</swiper-slide>
							<swiper-slide class="slide-item">
								<img
									class="img"
									src="../image/common/test3.jpg"
									alt=""
								/>
							</swiper-slide>
							<swiper-slide class="slide-item">
								<img
									class="img"
									src="../image/common/test4.jpg"
									alt=""
								/>
							</swiper-slide>
                            
							<swiper-slide class="slide-item">
								<img
									class="img"
									src="../image/common/test1.jpg"
									alt=""
								/>
							</swiper-slide> -->
                           
							<div
								class="swiper-button-next swiper-button-black"
								slot="button-next"
							></div>
							<div
								class="swiper-button-prev swiper-button-black"
								slot="button-prev"
							></div> 
						</swiper>
						<!-- swiper2 Thumbs -->
						<swiper
							class="swiper gallery-thumbs "
                            :class="dataInfo.carouselImgUrl.length<5 ? 'cus-swiper':''"
							:options="swiperOptionThumbs"
							ref="swiperThumbs"
						>


                            <swiper-slide 
                                v-for="(item,index) in dataInfo.carouselImgUrl"
                                :key="index"    
                                class="slide-item">
								<img
									class="img"
									:src="item"
									alt=""
								/>
							</swiper-slide>
                            
							<!-- <swiper-slide class="slide-item">
								<img
									class="img"
									src="../image/common/test2.jpg"
									alt=""
								/>
							</swiper-slide>
							<swiper-slide class="slide-item">
								<img
									class="img"
									src="../image/common/test3.jpg"
									alt=""
								/>
							</swiper-slide>
							<swiper-slide class="slide-item">
								<img
									class="img"
									src="../image/common/test4.jpg"
									alt=""
								/>
							</swiper-slide>
                            
							<swiper-slide class="slide-item">
								<img
									class="img"
									src="../image/common/test1.jpg"
									alt=""
								/>
							</swiper-slide> -->
						</swiper>
					</div>
                    <div class="desc-box f-y-c-s">
                        <div class="list f-x-s-c">
                            <div class="list-item f-x-c-c">{{ formartData('desStyle',dataInfo.designStyle) }}</div>
                            <div class="list-item f-x-c-c">{{ formartData('houseStyle',dataInfo.houseType) }}</div>
                            <div class="list-item f-x-c-c">{{ formartData('area',dataInfo.houseArea) }}</div>
                        </div>
                        <span class="text1">设计说明:</span>
                        <div  class="design-content">
                            {{dataInfo.designRemark}}
                        </div>
                    </div>
					<div class="options f-x-c-c">
						<div
							@click="navToDetail(preBtnInfo.id)"
							v-if="preBtnInfo.show"
							class="btn"
						>
							上一个案例:{{ preBtnInfo.title }}
						</div>
						<div
							@click="navToDetail(nextBtnInfo.id)"
							v-if="nextBtnInfo.show"
							class="btn"
						>
							下一个案例：{{ nextBtnInfo.title }}
						</div>
					</div>
				</div>
				<div class="activity">
					<Activity></Activity>
				</div>
				<div v-if="loveList.length" class="about f-y-s-c">
					<div class="title f-x-s-c">
						<span class="text1">猜你喜欢</span>
						<div class="cus-line"></div>
					</div>
					<div class="list">
						<div
							v-for="(item, index) in loveList.slice(0, 4)"
							:key="index"
							class="item f-y-e-c"
							:style="`background:center/cover url(${item.coverUrl})`"
							@click="navToDetail(item.id)"
						>
							<!-- <img
								class="img"
								src="../image/index/index_sample.png"
								alt=""
							/>
							<div class="text2">{{ item.villageName }}</div> -->
						</div>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="info f-y-c-c">
					<span class="title">本案例设计师</span>
					<div class="det f-y-c-c">
                        <div @click="navToDesign(curDesInfo.id)" class="avatar f-y-e-c" :style="`background:center/cover url(${curDesInfo.headerImgUrl})`">
                            <span @click.stop="openDialog" class="text1 f-x-c-c">找TA设计</span>
                        </div>
                        <span class="text1 ">{{ curDesInfo.name }}</span>
                        <div class="text-box f-x-b-c">
                            <span class="text2">从业{{curDesInfo.workingLife}}年</span>
                            <span class="text3">|</span>
                            <span class="text2">{{
                                formartData('ranks', curDesInfo.ranks)
                            }}</span>
                        </div>
                    </div>
				</div>
				<div class="from">
					<VisitorForm
						title="免费装修报价"
						desc="我家按本案例装修需要多少钱？"
                        adUnitCode="zxcv"
					></VisitorForm>
				</div>

				<div v-if="otherCaseList.length" class="recommend"  ref="refRecommend">
					<span class="text4">该门店其他案例</span>
					<div
						v-for="(item, index) in otherCaseList.slice(0, 3)"
						:key="index"
						:class="index == 0 ? 'no-border' : ''"
						:style="`{border-top:}`"
						class="recommend-item"
						@click="navToDetail(item.id)"
					>
						<img class="img" :src="item.coverUrl" alt="" />
						<div class="text1 eps1">{{ item.villageName }}</div>
					</div>
				</div>

                <transition name="el-fade-in"  mode="out-in">
                    <div v-if="showFixedForm" class="from-fixed">
                        <VisitorForm
                            title="免费装修报价"
                            desc="我家按本案例装修需要多少钱？"
                            adUnitCode="zxcv"
                        ></VisitorForm>
                    </div>
                </transition>
			</div>
		</div>
		<!-- 底部 -->
		<Footer></Footer>

        <!-- 右浮窗和底部吸顶 -->
        <Floating></Floating>
        <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
        <Tabbar></Tabbar>

        <DialogForm ref="refDialogForm" adUnitCode="zxcv"></DialogForm>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import AboutNav from '../components/AboutNav.vue'
import HouseInfo from '../components/HouseInfo.vue'
import Activity from '../components/Activity.vue'
import VisitorForm from '../components/VisitorForm.vue'
import {
	useSalesRoomDict,
	useDesDict,
	useHouseAreasDict,
	useHousetypeDict,
	useDesStyleDict,
	useDesRankDict,
    useCaseDict 
} from '@/utils/frequent.js'
import { codeToText } from 'element-china-area-data'
import { getCaseDetail } from '@/api/case.js'

export default {
	components: {
		NavBar,
		Footer,
		AboutNav,
		HouseInfo,
		Activity,
		VisitorForm,
	},
	data() {
		return {
			codeToText,
			caseList: [],
			ranksList: [],
			loveList: [],
			desDict: [],
			curId: '',
			dataInfo: {},
			otherCaseList: [],
			preBtnInfo: {
				show: false,
			},
			nextBtnInfo: {
				show: false,
			},
			curDesInfo: {},
			params: {},


            swiperOptionTop: {
                // loop: true,
                loopedSlides: 8, // looped slides should be the same
                spaceBetween: 10,
                slidesPerView: 'auto',
                navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev'
                }
            },
            swiperOptionThumbs: {
                // loop: true,
                loopedSlides: 8, // looped slides should be the same
                spaceBetween: 10,
                centeredSlides: true,
                slidesPerView: 'auto',
                touchRatio: 0.2,
                slideToClickedSlide: true
            },
            showFixedForm:false,
		}
	},
	async mounted() {
        this.init()
        window.addEventListener("scroll", this.scrollToTop);
	},
	methods: {
        navToDesign(id){
            this.$router.push('/design/detail?id='+id)
        },
        scrollToTop(){
            const scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            let height = 1900 
            if(this.$refs.refRecommend){
                const recHeight =  this.$refs.refRecommend.clientHeight
                height += recHeight
            }
            if (scrollTop >= height) {
                this.showFixedForm = true;
            } else  {
                this.showFixedForm = false;
            }
        },
        async init(){
            this.curId = this.$route.query.id
            const { salesRoomNo, houseType, designStyle } = this.$route.query
            this.params = {
                size: 1000,
                current: 1,
                salesRoomNo,
                houseType,
                designStyle,
            }
            if(this.curCityInfo.value=='00'){
                this.caseDict = (await useCaseDict(this.params)).filter(item=>item.isHeadOffice ==1)
            }else{
                this.caseDict = (await useCaseDict(this.params)).filter(item=>item.areaCode ==this.curCityInfo.value)
            }
            
            this.desDict = await useDesDict()



            this.otherCaseList = this.caseDict.filter(
                (item) => {
                    if(item.id != this.curId && !item.isRecommend) return true
                }
            )
            this.loveList = this.caseDict.filter(
                (item) => {
                    if(item.id != this.curId && item.isRecommend) return true
                }
            )

            this.getDetailData()
            this.setBtnData()

            this.salesRoomList = await useSalesRoomDict()
            this.desStyleList = await useDesStyleDict()
            this.housetypeList = await useHousetypeDict()
            this.houseAreasList = await useHouseAreasDict()
            
        },
        navToDetail(id){
            if(id=='-1') return 
            this.$router.push({
                path:'/investor/detail',
                query:{
                    id
                }
            })
        },
        openDialog(){
            this.$refs.refDialogForm.showDialog('design')
        },
		async getDetailData() {
			try {
				const res = await getCaseDetail(this.curId)
				if (res.code == 0) {
					this.dataInfo = res.data
                    // this.dataInfo.carouselImgUrl = [...res.data.carouselImgUrl,...res.data.carouselImgUrl]
                    // const len =res.data.carouselImgUrl.length
                    // if(len<5){
                    //     const num = 5-len
                    //     const list = 
                    // }
					this.setCurDesList(this.dataInfo.designerId)
                    this.swiperOptionThumbs.initialSlide = Math.floor(res.data.carouselImgUrl.length/2) 
                    this.swiperOptionTop.initialSlide = Math.floor(res.data.carouselImgUrl.length/2) 
                    // this.swiperOptionThumbs.slidesPerView = res.data.carouselImgUrl.length
                    this.$nextTick(() => {
                        const swiperTop = this.$refs.swiperTop.$swiper
                        const swiperThumbs = this.$refs.swiperThumbs.$swiper
                        swiperThumbs.controller.control = swiperTop
                        swiperTop.controller.control = swiperThumbs

                    })
				}
			} catch (err) {
				console.log(err)
			}
		},
		setBtnData() {
			const index = this.caseDict.findIndex(
				(item) => item.id == this.curId
			)
			if (index != -1) {
				if (index != 0) {
					this.preBtnInfo = {
						id: this.caseDict[index - 1].id,
						title: this.caseDict[index - 1].villageName,
						show: true,
					}
				}else{
                    this.preBtnInfo = {
                        id:-1,
                        title:'无',
                        show:true
                    }
                }
				if (index != this.caseDict.length - 1) {
					this.nextBtnInfo = {
						id: this.caseDict[index + 1].id,
						title: this.caseDict[index + 1].villageName,
						show: true,
					}
				}else{
                    this.nextBtnInfo = {
                        id:-1,
                        title:'无',
                        show:true
                    }
                }
			}
		},
		setCurDesList(designerId) {
			this.curDesInfo = this.desDict.find(
				(item) => item.id == designerId
			)
		},
	},
    beforeDestroy(){
        window.removeEventListener("scroll",this.scrollToTop)
    }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .case-detail {
        overflow-x: hidden;
		.box {
            display: none;
		}
        .box-mob{
            width: 100%;
			height: 160px;
			margin-top: 43px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
        }
        .content {
			display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: column;
            padding: 0 10px 30px 10px;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			background: #ffffff;
			.left {
				width: 100%;
				margin-top: 50px;
				.info {
					width: 100%;
					.title {
						
						font-weight: bold;
						font-size: 20px;
						color: #241934;
						line-height: 30px;
						// text-overflow: ellipsis;
						// white-space: nowrap;
						// overflow: hidden;
                        width: 100%;
					}
                    .det-box{
                        margin-top: 10px;
                        width: 100%;
                        .text1{
                            
                            font-weight: 400;
                            font-size: 13px;
                            color: #241934;
                            margin-right: 25px;
                        }
                        .text2{
                            
                            font-weight: 400;
                            font-size: 13px;
                            color: #241934;
                        }
                    }
					.swiper-box {
						width: 100%;
                        margin-top: 15px;
						// height: 480px;
						// background-color:#000;

						.swiper {
							.swiper-button-next,
							.swiper-button-prev {
								width: 45px;
								height: 45px;
								background: #ffffff;
								border-radius: 50%;
								opacity: 0.8;
							}
							.swiper-button-next::after {
								margin-left: 4px;
                                margin-top: 2px;
                                font-size: 20px;
                                font-weight: 600;
							}
							.swiper-button-prev::after {
								margin-right: 4px;
								margin-top: 2px;
                                font-size: 20px;
                                font-weight: 600;
							}
							.swiper-slide {
								background-size: cover;
								background-position: center;
								.img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
								&.slide-1 {
									background-image: url('../image/common/test1.jpg');
								}
								&.slide-2 {
									background-image: url('../image/common/test2.jpg');
								}
								&.slide-3 {
									background-image: url('../image/common/test3.jpg');
								}
								&.slide-4 {
									background-image: url('../image/common/test4.jpg');
								}
								&.slide-5 {
									background-image: url('../image/common/test2.jpg');
								}
							}

							&.gallery-top {
								height: 250px;
								width: 100%;
							}
                            &.cus-swiper{
                                /deep/ .swiper-wrapper{
                                    display: flex;
                                    justify-content: center;
                                    transform:none !important;
                                }
                            }
							&.gallery-thumbs {
								width: 100%;
								height: 70px;
								box-sizing: border-box;
								padding: 10px 0;
								box-sizing: border-box;
							}
							 &.gallery-thumbs .swiper-slide {
								width: 18%;
								height: 100%;
								padding: 1px;
								box-sizing: border-box;
								border: 1px solid #e8e8e8;
							}
							&.gallery-thumbs .swiper-slide-active {
								border: 1px solid #8654c6;
							}
						}
					}
					.desc-box{
                        margin-top: 5px;
                        width: 100%;
                        .list{
                            width: 100%;
                            .list-item{
                                width: 80px;
                                height: 30px;
                                background: #F5F4F4;
                                border-radius: 2px;

                                
                                font-weight: 400;
                                font-size: 12px;
                                color: #221732;
                                margin-right: 15px;
                            }
                        }
                        
                        .text1{
                            
                            font-weight: bold;
                            font-size: 16px;
                            color: #221732;
                            margin-top: 15px;
                        }
                        .design-content{
                            margin-top: 5px;
                            width: 100%;
                            
                            font-weight: 400;
                            font-size: 13px;
                            color: #000000;
                            line-height: 20px;
                            // /deep/ p{
                            //     
                            //     font-size: 12px;
                            //     line-height: 20px;
                            // }
                            // margin-top: 10px;
                            // /deep/img{
                            //     max-width: 100%;
                            // }
                            // /deep/ .ql-align-right{
                            //     text-align: right;
                            // }
                            // /deep/ .ql-align-center{
                            //     text-align: center;
                            // }
                        }
                    }
					
					.options {
						margin-top: 35px;
						width: 100%;
						.btn:first-child {
							margin-right: 10px;
						}
						.btn {
							cursor: pointer;
							width: 160px;
							height: 35px;
							line-height: 35px;
							background: #f5f4f4;
							border-radius: 20px;
							border: 1px solid #e5e5e5;

							
							font-weight: 400;
							font-size: 12px;
							color: #221732;
							padding: 0 10px;
							box-sizing: border-box;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							text-align: center;
						}
					}
				}
				.about {
                    display: none;
				}
				.activity {
					margin-top: 50px;
					width: 100%;
				}
			}
			.right {
				width: 100%;
				// height: 800px;
				margin-top: 50px;
				.info {
					width: 100%;
					// height: 426px;
					background: #ffffff;
					box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
					border-radius: 5px;
					padding: 10px 80px;
					box-sizing: border-box;
                    cursor: pointer;
					.title {
						
						font-weight: bold;
						font-size: 18px;
						color: #140626;
					}
					.det{
                        margin-top: 10px;
                        width: 100%;
                        box-sizing: border-box;
                        .avatar{
                            width: 100%;
                            height: 200px;
                            background-size: 100% 100% !important;
                            background-repeat: no-repeat !important;
                            // background-position: center 20% !important;
                            .text1{
                                width: 100%;
                                height: 30px;
                                background: rgba(117, 60, 190, 0.85);
                                border-radius: 2px;
                             
                                
                                font-weight: 400;
                                font-size: 13px;
                                color: #FFFFFF;
                            }

                        }
                        .text1{
                            
                            font-weight: bold;
                            font-size: 13px;
                            color: #140626;
                            margin-top: 15px;
                        }
                        .text-box{
                            width: 100%;
                            margin-top: 15px;
                            .text2{
                                
                                font-weight: 400;
                                font-size: 12px;
                                color: #5D5665;
                            }
                            .text3{
                                font-size: 12px;
                            }
                        }
                    }
				}
				.from {
					width: 100%;
					margin-top: 50px;
                    // padding: 0 40px;
                    box-sizing: border-box;
				}
                .from-fixed{
                    display: none;
                }
				.recommend {
					display: flex;
					flex-flow: column;
					width: 100%;
					background: #ffffff;
					box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
					border-radius: 5px;
					margin-top: 50px;
					padding: 0 10px;
					box-sizing: border-box;
					.text4 {
						
						font-weight: bold;
						font-size: 18px;
						color: #140626;
						line-height: 36px;
						margin: 15px auto 0px auto;
					}
					.no-border {
						border: none !important;
					}
					.recommend-item {
						padding: 20px 0;
						box-sizing: border-box;
						width: 100%;
						// height: 490px;
						position: relative;
						border-top: 1px solid #e5e5e5;
						.img {
							width: 100%;
							height: 150px;
							border-radius: 2px;
                            object-fit: cover;
						}
						.text1 {
							
							font-weight: 400;
							font-size: 14px;
							color: #140626;
							line-height: 14px;
							margin-top: 8px;
							width: 100%;
						}
					}
				}
			}
		}
    }
}
@media screen and (min-width: 900px) and(max-width:1359px) {
	.case-detail {
		height: 100%;
		.box {
			width: 100%;
			height: 230px;
			// margin-top: 96px;
		}
        .box-mob{
            display: none;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 0 2% 60px 2%;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: stretch;
			justify-content: center;
			background: #ffffff;
			.left {
				min-width: 1020px;
                // flex: 1;
				margin-right: 67px;
				margin-top: 60px;
				.info {
					width: 100%;
					.title {
                        width: 100%;
						font-weight: bold;
						font-size: 60px;
						color: #241934;
						line-height: 70px;
						// text-overflow: ellipsis;
						// white-space: nowrap;
						// overflow: hidden;
					}
                    .det-box{
                        margin-top: 35px;
                        width: 100%;
                        .text1{
                            
                            font-weight: 400;
                            font-size: 28px;
                            color: #241934;
                            margin-right: 50px;
                        }
                        .text2{
                            
                            font-weight: 400;
                            font-size: 28px;
                            color: #241934;
                        }
                    }
					.swiper-box {
						width: 100%;
                        margin-top: 36px;
						// height: 480px;
						// background-color:#000;

						.swiper {
							.swiper-button-next,
							.swiper-button-prev {
								width: 90px;
								height: 90px;
								background: #ffffff;
								border-radius: 50%;
								opacity: 0.8;
							}
							.swiper-button-next::after {
								margin-left: 5px;
							}
							.swiper-button-prev::after {
								margin-right: 5px;
							}
							.swiper-slide {
								background-size: cover;
								background-position: center;
								.img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
								&.slide-1 {
									background-image: url('../image/common/test1.jpg');
								}
								&.slide-2 {
									background-image: url('../image/common/test2.jpg');
								}
								&.slide-3 {
									background-image: url('../image/common/test3.jpg');
								}
								&.slide-4 {
									background-image: url('../image/common/test4.jpg');
								}
								&.slide-5 {
									background-image: url('../image/common/test2.jpg');
								}
							}

							&.gallery-top {
								height: 656px;
								width: 100%;
							}
                            &.cus-swiper{
                                /deep/ .swiper-wrapper{
                                    display: flex;
                                    justify-content: center;
                                    transform:none !important;
                                }
                            }
							&.gallery-thumbs {
								width: 100%;
								height: 143px;
								box-sizing: border-box;
								padding: 20px 0;
								box-sizing: border-box;
							}
							&.gallery-thumbs .swiper-slide {
								width: 19% !important;
								height: 100%;
								padding: 2px;
								box-sizing: border-box;
								border: 1px solid #e8e8e8;
							}
							&.gallery-thumbs .swiper-slide-active {
								border: 1px solid #8654c6;
							}
						}
					}
					.desc-box{
                        margin-top: 25px;
                        width: 100%;
                        .list{
                            width: 100%;
                            .list-item{
                                width: 160px;
                                height: 60px;
                                background: #F5F4F4;
                                border-radius: 4px;

                                
                                font-weight: 400;
                                font-size: 30px;
                                color: #221732;
                                margin-right: 30px;
                            }
                        }
                        
                        .text1{
                            
                            font-weight: bold;
                            font-size: 36px;
                            color: #221732;
                            margin-top: 40px;
                        }
                        .text2{
                            
                            font-weight: 400;
                            font-size: 30px;
                            color: #000000;
                            margin-top: 28px;
                            line-height: 44px;
                        }
                        .design-content{
                            width: 100%;
                            
                            font-weight: 400;
                            font-size: 30px;
                            color: #000000;
                            line-height: 44px;
                            margin-top: 10px;
                            // /deep/ p{
                            //     
                            //     font-size: 12px;
                            //     line-height: 20px;
                            // }
                            // margin-top: 10px;
                            // /deep/img{
                            //     max-width: 100%;
                            // }
                            // /deep/ .ql-align-right{
                            //     text-align: right;
                            // }
                            // /deep/ .ql-align-center{
                            //     text-align: center;
                            // }
                        }
                    }
					
					.options {
						margin-top: 70px;
						width: 100%;
						.btn:first-child {
							margin-right: 30px;
						}
						.btn {
							cursor: pointer;
							width: 458px;
							height: 80px;
							line-height: 80px;
							background: #f5f4f4;
							border-radius: 40px;
							border: 1px solid #e5e5e5;

							
							font-weight: 400;
							font-size: 30px;
							color: #221732;
							padding: 0 38px;
							box-sizing: border-box;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							text-align: center;
						}
					}
				}
				.about {
					width: 100%;
					margin-top: 60px;
					.title {
						width: 100%;
						.text1 {
							
							font-weight: bold;
							font-size: 36px;
							color: #241934;
							line-height: 36px;
							margin-right: 20px;
						}
						.cus-line {
							flex: 1;
							height: 2px;
							background: #6c1ec8;
						}
					}
					.list {
						margin-top: 33px;
						width: 100%;
						display: grid;
						justify-content: space-between;
						grid-template-columns: repeat(auto-fill, 49%);
                        cursor: pointer;
                        .item:nth-child(0){
                            margin-bottom: 40px;
                        }
                        .item:nth-child(1){
                            margin-bottom: 40px;
                        }
						.item {
							width: 100%;
							height: 320px;
							box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.11);
							border-radius: 10px;
							overflow: hidden;
							background-size: 100% 100%;
							background-repeat: no-repeat;
							.img {
								width: 185px;
								height: 185px;
								opacity: 0.8;
                                object-fit: cover;
							}
							.text2 {
								margin-top: 30px;
								width: 100%;
								height: 80px;
								background: rgba(0, 0, 0, 0.5);
								border-radius: 0 0 10px 10px;
								
								font-weight: 400;
								font-size: 30px;
								color: #ffffff;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
								box-sizing: border-box;
								padding: 0 30px;
								text-align: left;
								line-height: 80px;
							}
						}
					}
				}
				.activity {
					margin-top: 60px;
					width: 100%;
				}
			}
			.right {
				width: 469px;
				// height: 800px;
				margin-top: 60px;
				.info {
					width: 100%;
					// height: 426px;
					background: #ffffff;
					box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
					border-radius: 10px;
					padding: 30px;
					box-sizing: border-box;
                    cursor: pointer;
					.title {
						
						font-weight: bold;
						font-size: 36px;
						color: #140626;
					}
					.det{
                        margin-top: 26px;
                        .avatar{
                            width: 280px;
                            height: 330px;
                            // background-size: 100% 100% !important;
                            // background-repeat: no-repeat !important;
                            .text1{
                                width: 100%;
                                height: 66px;
                                background: rgba(117, 60, 190, 0.85);
                                border-radius: 4px;
                             
                                
                                font-weight: 400;
                                font-size: 28px;
                                color: #FFFFFF;
                            }

                        }
                        .text1{
                            
                            font-weight: bold;
                            font-size: 30px;
                            color: #140626;
                            margin-top: 30px;
                        }
                        .text-box{
                            width: 100%;
                            margin-top: 30px;
                            .text2{
                                
                                font-weight: 400;
                                font-size: 28px;
                                color: #5D5665;
                            }
                            .text3{
                                font-size: 23px;
                            }
                        }
                    }
				}
				.from {
					width: 100%;
					margin-top: 60px;
                    
				}
                .from-fixed{
                    width: 100%;
					// margin-top: 60px;
                    position: sticky;
                    top: 30px;
                }
				.recommend {
					display: flex;
					flex-flow: column;
					width: 100%;
					background: #ffffff;
					box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
					border-radius: 10px;
					margin-top: 60px;
					padding: 0 30px;
					box-sizing: border-box;
					.text4 {
						
						font-weight: bold;
						font-size: 36px;
						color: #140626;
						line-height: 36px;
						margin: 30px auto 0px auto;
					}
					.no-border {
						border: none !important;
					}
					.recommend-item {
                        cursor: pointer;
						padding: 40px 0;
						box-sizing: border-box;
						width: 100%;
						// height: 490px;
						position: relative;
						border-top: 1px solid #e5e5e5;
						.img {
							width: 410px;
							height: 297px;
							border-radius: 4px;
						}
						.text1 {
							
							font-weight: 400;
							font-size: 30px;
							color: #140626;
							line-height: 30px;
							margin-top: 16px;
							width: 100%;
						}
					}
				}
			}
		}
	}
}
@media screen and (min-width: 1360px) {
	.case-detail {
		height: 100%;
		.box {
			width: 100%;
			height: 230px;
			// margin-top: 96px;
		}
        .box-mob{
            display: none;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 0 180px 60px 180px;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: stretch;
			justify-content: center;
			background: #ffffff;
			.left {
                // flex: 1;
				width: 1020px;
				margin-right: 67px;
				margin-top: 60px;
				.info {
					width: 100%;
					.title {
                        width: 100%;
						
						font-weight: bold;
						font-size: 60px;
						color: #241934;
						line-height: 70px;
						// text-overflow: ellipsis;
						// white-space: nowrap;
						// overflow: hidden;
					}
                    .det-box{
                        margin-top: 35px;
                        width: 100%;
                        .text1{
                            
                            font-weight: 400;
                            font-size: 28px;
                            color: #241934;
                            margin-right: 50px;
                        }
                        .text2{
                            
                            font-weight: 400;
                            font-size: 28px;
                            color: #241934;
                        }
                    }
					.swiper-box {
						width: 100%;
                        margin-top: 36px;
						// height: 480px;
						// background-color:#000;

						.swiper {
							.swiper-button-next,
							.swiper-button-prev {
								width: 90px;
								height: 90px;
								background: #ffffff;
								border-radius: 50%;
								opacity: 0.8;
							}
							.swiper-button-next::after {
								margin-left: 5px;
							}
							.swiper-button-prev::after {
								margin-right: 5px;
							}
							.swiper-slide {
								background-size: cover;
								background-position: center;
								.img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
								&.slide-1 {
									background-image: url('../image/common/test1.jpg');
								}
								&.slide-2 {
									background-image: url('../image/common/test2.jpg');
								}
								&.slide-3 {
									background-image: url('../image/common/test3.jpg');
								}
								&.slide-4 {
									background-image: url('../image/common/test4.jpg');
								}
								&.slide-5 {
									background-image: url('../image/common/test2.jpg');
								}
							}

							&.gallery-top {
								height: 656px;
								width: 100%;
							}
                            &.cus-swiper{
                                /deep/ .swiper-wrapper{
                                    display: flex;
                                    justify-content: center;
                                    transform:none !important;
                                }
                            }
							&.gallery-thumbs {
								// width: 100%;
								height: 143px;
								box-sizing: border-box;
								padding: 20px 0;
								box-sizing: border-box;
                                
                                /deep/ .swiper-wrapper{
                                    display: flex;
                                    // justify-content: center;
                                    // transform:none !important;
                                }
                                .swiper-slide {
                                    width: 19% !important;
                                    height: 100%;
                                    padding: 2px;
                                    box-sizing: border-box;
                                    border: 1px solid #e8e8e8;
                                    .img{
                                        object-fit: cover;
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
							}
							&.gallery-thumbs .swiper-slide-active {
								border: 1px solid #8654c6;
							}
						}
					}
					.desc-box{
                        margin-top: 25px;
                        width: 100%;
                        .list{
                            width: 100%;
                            .list-item{
                                width: 160px;
                                height: 60px;
                                background: #F5F4F4;
                                border-radius: 4px;

                                
                                font-weight: 400;
                                font-size: 30px;
                                color: #221732;
                                margin-right: 30px;
                            }
                        }
                        
                        .text1{
                            
                            font-weight: bold;
                            font-size: 36px;
                            color: #221732;
                            margin-top: 40px;
                        }
                        .text2{
                            
                            font-weight: 400;
                            font-size: 30px;
                            color: #000000;
                            margin-top: 28px;
                            line-height: 44px;
                        }
                        .design-content{
                            width: 100%;
                            
                            font-weight: 400;
                            font-size: 30px;
                            color: #000000;
                            line-height: 44px;
                            margin-top: 10px;
                            // /deep/ p{
                            //     
                            //     font-size: 12px;
                            //     line-height: 20px;
                            // }
                            // margin-top: 10px;
                            // /deep/img{
                            //     max-width: 100%;
                            // }
                            // /deep/ .ql-align-right{
                            //     text-align: right;
                            // }
                            // /deep/ .ql-align-center{
                            //     text-align: center;
                            // }
                        }
                    }
					
					.options {
						margin-top: 70px;
						width: 100%;
						.btn:first-child {
							margin-right: 30px;
						}
						.btn {
							cursor: pointer;
							width: 458px;
							height: 80px;
							line-height: 80px;
							background: #f5f4f4;
							border-radius: 40px;
							border: 1px solid #e5e5e5;

							
							font-weight: 400;
							font-size: 30px;
							color: #221732;
							padding: 0 38px;
							box-sizing: border-box;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							text-align: center;
						}
					}
				}
				.about {
					width: 100%;
					margin-top: 60px;
					.title {
						width: 100%;
						.text1 {
							
							font-weight: bold;
							font-size: 36px;
							color: #241934;
							line-height: 36px;
							margin-right: 20px;
						}
						.cus-line {
							flex: 1;
							height: 2px;
							background: #6c1ec8;
						}
					}
					.list {
						margin-top: 33px;
						width: 100%;
						display: grid;
						justify-content: space-between;
						grid-template-columns: repeat(auto-fill, 49%);
                        cursor: pointer;
                        .item:nth-child(0){
                            margin-bottom: 40px;
                        }
                        .item:nth-child(1){
                            margin-bottom: 40px;
                        }
						.item {
							width: 100%;
							height: 320px;
							box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.11);
							border-radius: 10px;
							overflow: hidden;
							background-size: 100% 100%;
							background-repeat: no-repeat;
							.img {
								width: 185px;
								height: 185px;
								opacity: 0.8;
                                object-fit: cover;
							}
							.text2 {
								margin-top: 30px;
								width: 100%;
								height: 80px;
								background: rgba(0, 0, 0, 0.5);
								border-radius: 0 0 10px 10px;
								
								font-weight: 400;
								font-size: 30px;
								color: #ffffff;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
								box-sizing: border-box;
								padding: 0 30px;
								text-align: left;
								line-height: 80px;
							}
						}
					}
				}
				.activity {
					margin-top: 60px;
					width: 100%;
				}
			}
			.right {
				width: 469px;
				// height: 800px;
				margin-top: 60px;
				.info {
					width: 100%;
					// height: 426px;
					background: #ffffff;
					box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
					border-radius: 10px;
					padding: 30px;
					box-sizing: border-box;
                    cursor: pointer;
					.title {
						
						font-weight: bold;
						font-size: 36px;
						color: #140626;
					}
					.det{
                        margin-top: 26px;
                        .avatar{
                            width: 280px;
                            height: 330px;
                            // background-size: 100% 100% !important;
                            // background-repeat: no-repeat !important;
                            .text1{
                                width: 100%;
                                height: 66px;
                                background: rgba(117, 60, 190, 0.85);
                                border-radius: 4px;
                             
                                
                                font-weight: 400;
                                font-size: 28px;
                                color: #FFFFFF;
                            }

                        }
                        .text1{
                            
                            font-weight: bold;
                            font-size: 30px;
                            color: #140626;
                            margin-top: 30px;
                        }
                        .text-box{
                            width: 100%;
                            margin-top: 30px;
                            .text2{
                                
                                font-weight: 400;
                                font-size: 28px;
                                color: #5D5665;
                            }
                            .text3{
                                font-size: 23px;
                            }
                        }
                    }
				}
				.from {
					width: 100%;
					margin-top: 60px;
                    
				}
                .from-fixed{
                    width: 100%;
					// margin-top: 60px;
                    position: sticky;
                    top: 30px;
                }
				.recommend {
					display: flex;
					flex-flow: column;
					width: 100%;
					background: #ffffff;
					box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
					border-radius: 10px;
					margin-top: 60px;
					padding: 0 30px;
					box-sizing: border-box;
					.text4 {
						
						font-weight: bold;
						font-size: 36px;
						color: #140626;
						line-height: 36px;
						margin: 30px auto 0px auto;
					}
					.no-border {
						border: none !important;
					}
					.recommend-item {
                        cursor: pointer;
						padding: 40px 0;
						box-sizing: border-box;
						width: 100%;
						// height: 490px;
						position: relative;
						border-top: 1px solid #e5e5e5;
						.img {
							width: 410px;
							height: 297px;
							border-radius: 4px;
						}
						.text1 {
							
							font-weight: 400;
							font-size: 30px;
							color: #140626;
							line-height: 30px;
							margin-top: 16px;
							width: 100%;
						}
					}
				}
			}
		}
	}
}
</style>
